/* @jsx jsx */
import { jsx } from "theme-ui";
import { navigate } from "gatsby";
import { Layout } from "../components/Layout";
import SEO from "../components/Seo";
import config from "../config";

import { H2 } from "../components/Typography";
import { Button } from "../components/Button";
import { ReactComponent as GoogleLogo } from "../../static/google.svg";
import { useGetCurrentUserQuery } from "../graphql/queries/_generated";
import { useEffect } from "react";
import LoginForm from "../components/LoginForm";

const RedirectToApp = () => {
  const [{ data }] = useGetCurrentUserQuery();

  useEffect(() => {
    if (data?.currentUser) {
      navigate(`/app`);
    }
  }, [data?.currentUser]);

  return null;
};

export default () => {
  return (
    <Layout>
      <SEO title="Login" description="Feedback Fish - Login" />

      <RedirectToApp />
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          //px: 4,
          textAlign: "center",
        }}
      >
        <LoginForm type="login" />
      </div>
    </Layout>
  );
};
