/* @jsx jsx */
import { jsx } from "theme-ui";
import { Link, navigate } from "gatsby";
import { Layout } from "../components/Layout";
import SEO from "../components/Seo";
import qs from "querystring";
import config from "../config";

import { H2, P } from "../components/Typography";
import { Button } from "../components/Button";
import { ReactComponent as GoogleLogo } from "../../static/google.svg";
import { useGetCurrentUserQuery } from "../graphql/queries/_generated";
import { useEffect, useState } from "react";
import { Flex } from "../components/Flex";
import InputField from "../components/InputField";
import tw from "twin.macro";
import { notify } from "react-notify-toast";

const LoginForm = ({ type }: { type: "sign up" | "login" }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const submit = async (evt: any) => {
    evt && evt.preventDefault()
    setLoading(true);
    try {
      const res = await fetch(`${config.backendUrl}/auth/magiclink`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email.toLowerCase() }),
      }).then((res) => res.json());
      if (res) {
        navigate(
          `/check-mailbox?${qs.stringify({
            e: btoa(email),
            c: res.code,
          })}`
        );
      }
    } catch (err) {
      notify.show(err.message, "error");
      setLoading(false);
    }
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      gap={3}
      sx={{ ...tw`w-full md:w-2/3 lg:w-1/2 ` }}
    >
      <Flex
        flexDirection="column"
        gap={4}
        sx={{
          borderWidth: `1px`,
          borderStyle: `solid`,
          borderColor: `grey.3`,
          p: 4,
          boxShadow: "0 4px 5px rgba(0,0,0,0.04)",
          borderRadius: `10px`,
          // minWidth: `320px`,
          width: "100%",
        }}
      >
        <Flex gap={2} flexDirection="column">
          <H2 sx={{ m: 0, fontSize: [3, 4] }}>
            {type === "sign up" ? `Get started for free` : `Login`}
          </H2>
          {type === `sign up` && (
            <P sx={{ color: "gray.6" }}>
              Try the widget for free with no time limit or credit card
              requirement. Upgrade to unlock unlimited feedback submissions if
              you are happy. (
              <Link
                to="/help/free-plan-limits"
                sx={{ textDecoration: `underline` }}
              >
                learn more
              </Link>
              )
            </P>
          )}
        </Flex>
        <Flex gap={4} flexDirection="column">
          <Flex
            flexDirection="column"
            as="form"
            gap={3}
            // @ts-ignore
            onSubmit={submit}
          >
            <InputField
              label="Email"
              inputValue={email}
              onChange={(value) => setEmail(value)}
              placeholder="arnold@example.com"
            />
            <Button
              disabled={loading}
              // @ts-ignore
              type="submit"
              chevron={false}
              primary
              href="#"
              sx={{
                alignSelf: `flex-end`,
                ...tw`capitalize`,
                borderRadius: 6,
                width: "100%",
                justifyContent: "center",
                appearance: `none`,
              }}
              onClick={submit}
            >
              {type} with email
            </Button>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="center"
            sx={{ position: `relative` }}
          >
            <hr sx={{ position: `absolute`, left: 0, right: 0, top: `50%` }} />
            <p
              sx={{
                bg: `white`,
                zIndex: 1,
                px: 3,
                color: `gray.6`,
                fontSize: 1,
              }}
            >
              Or
            </p>
          </Flex>
          <Button
            href={`${config.backendUrl}/auth/google`}
            primary
            chevron={false}
            sx={{
              display: "flex",
              alignItems: "center",
              alignSelf: `center`,
              borderRadius: 6,
              width: "100%",
              justifyContent: "center",
              backgroundColor: "white",
              border: "2px solid",
              color: "brandBlue.2",
              borderColor: "brandBlue.2",
              ":hover": {
                backgroundColor: "blue.1",
              },
            }}
          >
            <GoogleLogo sx={{ mr: 2 }} />
            <span sx={{ mr: 2 }}>
              <span sx={tw`capitalize`}>{type}</span> with Google
            </span>
          </Button>
        </Flex>
        {type === `sign up` && (
          <p
            sx={{
              color: "gray.6",
              fontSize: 1,
              alignSelf: `center`,
              maxWidth: `350px`,
              textAlign: `center`,
            }}
          >
            Your information is private and secure. Feedback Fish only stores
            your email.
          </p>
        )}
      </Flex>
      {type === "sign up" && (
        <span sx={{ color: "gray.6" }}>
          Already have an account?{" "}
          <Link
            to="/login"
            sx={{
              textDecoration: "underline",
              ":hover": {
                color: "gray.8",
              },
            }}
          >
            Login
          </Link>
        </span>
      )}
    </Flex>
  );
};

export default LoginForm;
